import React from "react";
import Card from "../interface/Card";
import { Container, Box } from "@mui/material";
import "./CustomersBlock.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
const comments = [
  {
    flag: "ch",
    country: "Switzerland",
    name: "Stella",
    text: "As an Airbnb hostess, I recommend the Transferta card with IBAN account for receiving the rent payments. The apartment is in Brussels, I live in Zurich, and my guests are from around the globe. I can easily access the money on my card as Mastercard is accepted almost anywhere. ",
  },
  {
    flag: "nl",
    country: "The Netherlands",
    name: "Gerhard",
    text: "Satisfied after using Transferta account for ~6 months to receive payments from my freelance clients. The app and customer portal is simple and easy to use, so I don't spend much time managing my money. ",
  },
  {
    flag: "fr",
    country: "France",
    name: "Vivienne",
    text: "Prepaid Mastercard is the best solution for an obsessive shopper like myself. I keep the shopping money separately from the bill money, and my husband can't thank me enough.",
  },
  {
    flag: "de",
    country: "Germany",
    name: "Kristian",
    text: "Really easy to open account compared to my bank. I received my Transferta card just before a vacation, so it worked great as a travel money carrier, because impossible to overspend as it's with credit cards.",
  },
  {
    flag: "gb",
    country: "UK",
    name: "Victoria",
    text: "Prepaid Mastercard is the best solution for an obsessive shopper like myself. I keep the shopping money separately from the bill money, and my husband can't thank me enough.",
  },
];

const CustomersBlocks = () => {
  return (
    <Box
      className="content__box"
      sx={{ backgroundColor: "rgba(151, 71, 255, 0.1)" }}
    >
      <Container className='customers-container' style={{ overflow: "visible" }}>
        <h1 style={{ margin: 0, marginBottom: 8 }}>
          See what our happy customers are saying
        </h1>
        <p>See our reviews on<span><a href=''><img src="svg/trustpilot.svg" alt="" /></a></span></p>
        <Swiper
          slidesPerView={3}
          spaceBetween={12}
          modules={[FreeMode]}
          // parallax
          freeMode={true}
          className="swiper__wraper-freemode"
        >
          {comments.map((comment) => (
            <SwiperSlide className="card_container-swiper customer">
              <div className="card-small-customer_comment">
                <div className="card-small_content">
                  <div className="_flag-container">
                    <img src={`svg/flags/${comment.flag}.svg`} alt="" />
                  </div>
                  <h6>
                    {comment.name}, {comment.country}
                  </h6>
                  <p>{comment.text}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
      {/* <div className="card__container" style={{ display: 'flex', flexDirection: 'row', overflow: 'visible' }}> */}

      {/* </div> */}
    </Box>
  );
};

export default CustomersBlocks;
