import React from "react";
import "./MainInfo.scss";
import Button from "../interface/Button";

import {
  Container,
  Box,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  SvgIcon,
} from "@mui/material";

const CheckedSvg = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.09669 12.1699C5.45662 12.1699 5.72827 12.0178 5.92521 11.7204L12.3089 1.58087C12.4515 1.35955 12.5059 1.16589 12.5059 0.979144C12.5059 0.50191 12.1799 0.169922 11.7045 0.169922C11.3717 0.169922 11.1748 0.287501 10.9711 0.612573L5.06953 10.1572L2.02708 6.1803C1.83014 5.91056 1.6264 5.79989 1.32759 5.79989C0.852209 5.79989 0.505859 6.14571 0.505859 6.62295C0.505859 6.83044 0.580562 7.03102 0.750342 7.24543L4.26817 11.7273C4.50586 12.0247 4.75713 12.1699 5.09669 12.1699Z"
      fill="black"
    />
  </svg>
);

const MainInfo = ({toggleModal}) => {
  return (
    <Container className='main__info-container' sx={{ paddingTop: '80px', paddingBottom: '80px' }}>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <div className="main__info" style={{ flex: 1.1 }}>
          <h1>Open Your Online Current Account in 5 Minutes</h1>
          <p>
            Spend, save and manage your money, all in one place. Open a full UK
            bank account from your phone, for free.
          </p>
          <Button onClick={toggleModal} id='main-button' large 
          // href="http://my.transferta.ee/auth/signup/private"
          >
            Open my account
          </Button>
          <ul>
            <li>
              <CheckedSvg />
              Get up to 3 prepaid Mastercard cards
            </li>
            <li>
              <CheckedSvg />
              Get your virtual card in 5 minutes from registration
            </li>
            <li>
              <CheckedSvg />
              Pay, shop and receive salary in EUR currency
            </li>
          </ul>
        </div>
        <div className="main__info_image" style={{ flex: 1 }}>
          <img src="img/transferta-main.png" alt="" />
        </div>
      </Box>
    </Container>
  );
};

export default MainInfo;
