import React from "react";
import "./Block.scss";

const Block = ({ imageSrc, children, contentPostion = "right" }) => {
  const contentRight = contentPostion === "right";
  return (
    <div className="block">
      <div
        className="block__body"
        style={{
          padding: "26px 0px",
          display: "flex",
          flexDirection: contentRight ? "row" : "row-reverse",
        }}
      >
        <div className="block__body-img" style={{ flex: 1 }}>
          <img src={imageSrc} alt="" />
        </div>
        <div
          className="block__body-content"
          style={{
            flex: 1.5,
            padding: contentRight
              ? "65px 60px 65px 48px"
              : "65px 48px 65px 0px",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Block;
