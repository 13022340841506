import React from 'react';
import Card from '../interface/Card';
import { Container, Box } from '@mui/material';
import './WhyTransfertaBlock.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from "swiper";

import "swiper/css/free-mode";
import 'swiper/css';
const index = () => {
    return (
        <Box className='content__box' sx={{ backgroundColor: 'rgba(151, 71, 255, 0.1)' }}>
            <Container style={{ overflow: 'visible' }} >
                <h1 style={{ margin: 0, marginBottom: 24 }}> Why Transferta</h1>
                <Swiper
                    slidesPerView={3}
                    spaceBetween={12}
                    modules={[FreeMode]}
                    // parallax
                    freeMode={true}
                    className="swiper__wraper-freemode">
                    <SwiperSlide className='card_container-swiper'>
                        <div className="card">
                            <div className="icon-container">
                                <img src="svg/lightning.svg" alt="" />
                            </div>
                            <h2>
                                Better Than Your Regular Bank
                            </h2>
                            <ul>
                                <li>No annoying paperwork</li>
                                <li>Easy-to-use customer portal and mobile app</li>
                                <li>Fast identity verification to get all benefits</li>
                                <li>Up to 3 secondary cards for your family</li>
                            </ul>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='card_container-swiper'>
                        <div className="card">
                            <div className="icon-container">
                                <img src="svg/stop.svg" alt="" />
                            </div>
                            <h2>
                                Private & Secure Service
                            </h2>
                            <ul>
                                <li>Bank-level operation security level</li>
                                <li>Independent from your bank account</li>
                                <li>Protected by Mastercard 3D Secure</li>
                                <li>2-step verification for online purchases</li>
                            </ul>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='card_container-swiper'>
                        <div className="card">
                            <div className="icon-container">
                                <img src="svg/tablet.svg" alt="" />
                            </div>
                            <h2>
                                For All Your Financial Needs
                            </h2>
                            <ul>
                                <li>Receive salary and incoming payments</li>
                                <li>Shop online and in retail stores globally</li>
                                <li>Make fast outgoing SEPA payments</li>
                                <li>Keep track of your personal finances</li>
                            </ul>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='card_container-swiper'>
                        <div className="card">
                            <div className="icon-container">
                                <img src="svg/globe.svg" alt="" />
                            </div>
                            <h2>
                                Free Prepaid Mastercard
                            </h2>
                            <ul>
                                <li>Contactless payments at POS terminals</li>
                                <li>Free delivery anywhere in EEA countries</li>
                                <li>Accepted in over 41 million stores worldwide</li>
                                <li>Perfect for budgeting travelling expenses</li>
                            </ul>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className='card_container-swiper'>
                        <div className="card">
                            <div className="icon-container">
                                <img src="svg/euro.svg" alt="" />
                            </div>
                            <h2>
                                Low Product & Service Costs
                            </h2>
                            <ul>
                                <li>Open euro IBAN online for free</li>
                                <li>Only 2€ monthly service fee</li>
                                <li>Low, fixed ATM withdrawal fee</li>
                            </ul>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </Container>
            {/* <div className="card__container" style={{ display: 'flex', flexDirection: 'row', overflow: 'visible' }}> */}

            {/* </div> */}

        </Box>
    );
};

export default index;