import React, { memo } from 'react';
import { Stack, Box } from '@mui/material';
import Button from '../Button';
import './Header.scss';

const Header = ({ toggleModal }) => {
    return (
        <header>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <img src='logo.svg' height={34}></img>
                <Stack spacing={2} direction="row" className='header-nav_buttons'>
                    <Button 
                    onClick={toggleModal}
                        // href='http://my.transferta.ee/auth/login' 
                        secondary
                    >Log in</Button>
                    <Button 
                    onClick={toggleModal}
                    // href='http://my.transferta.ee/auth/signup/private'
                    >Open my account</Button>
                </Stack>
            </Box>
        </header>
    );
};

export default Header;