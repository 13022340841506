import ButtonUnstyled, {
  buttonUnstyledClasses,
} from "@mui/base/ButtonUnstyled";
import React from "react";
import { styled } from "@mui/system";

const blue = {
  500: "#9747FF",
  600: "#7639c7",
  700: "#0059B2",
};

const Button = ({ children, secondary, large, href, style, id, onClick }) => {
  const buttonStyle = {
    backgroundColor: secondary ? "#F5F5F5" : "#9747FF",
    color: secondary ? "#000000" : "#FFFFFF",
  };

  const CustomButton = styled(ButtonUnstyled)`
    font-weight: bold;
    font-size: ${large ? "18px" : "16px"};
    line-height: ${large ? "24px" : "20px"};
    background-color: ${secondary ? "#F5F5F5" : "#9747FF"};
    padding: ${large ? `12px 32px` : `12px 24px`};
    border-radius: 100px;
    color: ${secondary ? "#000000" : "#FFFFFF"};
    transition: all 150ms ease;
    cursor: pointer;
    border: none;

    &:hover {
      background-color: ${secondary ? "#e9e9e9" : "#642dab"};
    }

    &.${buttonUnstyledClasses.focusVisible} {
      box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1),
        0 0 0 5px rgba(0, 127, 255, 0.5);
      outline: none;
    }

    &.${buttonUnstyledClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;

  return (
    <a href={href}>
      <CustomButton onClick={onClick} id={id} style={style} href={href} variant="contained">
        {children}
      </CustomButton>
    </a>
  );
};

export default Button;
