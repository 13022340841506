import React from "react";
import "./HowItWorksBlock.scss";
import Button from "../interface/Button";
import { Container, Box } from "@mui/material";
import Card from "../interface/Card";

const HowItWorksBlock = ({toggleModal}) => {
  return (
    <Box
      className="howitworks__box"
      sx={{ backgroundColor: "rgba(151, 71, 255, 0.1)" }}
    >
      <Container>
        <div className="howitworks__box-content">
          <h1> How it works</h1>
          <h3 style={{ marginTop: 16, marginBottom: 32 }}>
            Access Your Account via Online Customer Portal or Transferta Mobile
            Application
          </h3>
          <Button large 
          onClick={toggleModal}
          // href="http://my.transferta.ee/auth/signup/private"
          >
            Open my account
          </Button>
          <div
            className="card__container"
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
              marginTop: 32,
            }}
          >
            <div className="card-small">
              <div className="card-small_content" style={{ marginBottom: 24 }}>
                <div className="_icon-number">1</div>
                <h6>Complete Transferta online signup from</h6>
              </div>
              <img src="img/small_card_1.png"></img>
            </div>
            <div className="card-small">
              <div className="card-small_content" style={{ marginBottom: 24 }}>
                <div className="_icon-number">2</div>
                <h6>Access your account right after registration</h6>
              </div>
              <img src="img/small_card_2.png"></img>
            </div>
            <div className="card-small">
              <div className="card-small_content" style={{ marginBottom: 24 }}>
                <div className="_icon-number">3</div>
                <h6>Get your virtual card in 5 minutes from registration</h6>
              </div>
              <img src="img/small_card_3.png"></img>
            </div>
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default HowItWorksBlock;
