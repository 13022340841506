import './App.css';

import React, { useState, useEffect } from 'react'
import Button from './components/interface/Button';
import Header from './components/interface/Header';
import Block from './components/interface/Block';
import MainInfo from './components/MainInfo';
import HowItWorksBlock from './components/HowItWorksBlock';
import Card from './components/interface/Card';
import WhyTransfertaBlock from './components/WhyTransfertaBlock'
import CustomersBlock from './components/CustomersBlock';
import Footer from './components/Footer';
import { Container, Backdrop, CircularProgress } from '@mui/material';
import { slide as Menu } from "react-burger-menu";
import './media.scss';
function App() {

  const [width, setWidth] = useState(window.innerWidth);
  const [scroll, setScroll] = useState(window.scrollY);
  const [isOpenModal, setOpenModal] = useState(false);
  const toggleModal = () => {
    setOpenModal((prev) => !prev);
  }

  const handleResize = (e) => {
    setWidth(window.innerWidth);
  };
  const handleScroll = (e) => {
    setScroll(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  })
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  })
  const mainButtonOffset = document.querySelector('#main-button')?.offsetTop ?? 0;
  const bottomButtonOffset = document.querySelector('#bottom-button')?.offsetTop ?? 0;
  const windowHeight = window.innerHeight ?? 0;
  // console.log('BBOFFS ', bottomButtonOffset);
  // console.log(scroll)
  return (
    <div className="App">
      <Backdrop
        sx={{ color: '#fff', zIndex: 2000, transition: '5s', }}
        open={isOpenModal}
        onClick={() => { }}
        transitionDuration={600}
      >
        <div className="modal" style={{
          // border: '8px solid #8863b78c',
          // transition: '5s',

          // animationDuration: '100s',
          // animationName: 'changewidth',
          // animationIterationCount: 'infinite',
          // animationDirection: 'alternate',
          width: '50%',
          height: 'min-content',
          backgroundColor: '#000000b8',
          // backdropFilter: 'blur(20px)',
          padding: 50,
          borderRadius: 30,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center'

        }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} className="modal-content">
            <svg style={{ flex: 1, fill: 'white', width: 80, marginBottom: 36 }} class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="InfoIcon"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></svg>
            <h1 style={{ flex: 5, textAlign: 'center', width: '80%' }}>Sorry, this functionality is under development</h1>
          </div>
          <Button onClick={toggleModal} style={{ marginTop: 56 }} large secondary>Close</Button>
        </div>
      </Backdrop>
      {width < 732 &&
        <Menu right pageWrapId={"page-wrap"}>
          <Button
            onClick={toggleModal} style={{ marginBottom: 24 }}
            // href='http://my.transferta.ee/auth/login' 
            secondary>Log in</Button>
          <Button
            onClick={toggleModal}
          // href='http://my.transferta.ee/auth/signup/private'
          >Open my account</Button>
        </Menu>
      }
      {(scroll > mainButtonOffset && (scroll + windowHeight) < bottomButtonOffset && width < 732) &&
        <Button style={{
          fontWeight: 'bold',
          fontSize: 18,
          lineHeight: 24,
          height: 48,
          padding: '12px 32px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 16px', position: 'fixed', bottom: 32, zIndex: 999, left: 0, width: '95%'
        }} 
        // href='http://my.transferta.ee/auth/signup/private'
        onClick={toggleModal}
        >Open my account</Button>
      }
      <Header toggleModal={toggleModal}></Header>
      <main>
        <MainInfo toggleModal={toggleModal} />
      </main>
      <WhyTransfertaBlock />

      <Container>
        <Block imageSrc={'img/block_image_1.png'}>
          <h1>Transferta app</h1>
          <h3>Access Your Account via Online Customer Portal or Transferta Mobile Application.</h3>
          <ul>
            <li>Avoid overspending by following your budget</li>
            <li>Make instant tranfers to another Transferta card</li>
            <li>View and export transaction history wherever you are</li>
          </ul>
          <p>
            Get the Transferta app directly on your phone:
          </p>
          <div className="qr_container">
            <img src="img/code.png" alt="" />
          </div>
        </Block>
      </Container>

      <HowItWorksBlock toggleModal={toggleModal} />

      <Container>
        <Block imageSrc={'img/block_image_2.png'} contentPostion="left">
          <h1>Designed in collaboration with Mastercard</h1>
          <h3>Use your Transferta prepaid Mastercard in ATM's, online and retail stores around the world!</h3>
          <div className="_desc" style={{ display: 'flex', flexDirection: 'row', gap: 32 }}>
            <div className="_desc-col" style={{ display: 'flex', flexDirection: 'column' }}>
              <span className='title__secondary'>41 mln</span>
              <span className='text__secondary'>locations</span>
            </div>
            <div className="_desc-col" style={{ display: 'flex', flexDirection: 'column' }}>
              <span className='title__secondary'>2.1 mln</span>
              <span className='text__secondary'>ATM's worldwide</span>
            </div>
          </div>


        </Block>
      </Container>

      <CustomersBlock />

      <Container sx={{ padding: '80px 0px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ marginBottom: 32 }}>
          <h1 style={{ textAlign: 'center' }}>Safe choice.</h1>
          <h1 style={{ textAlign: 'center' }}>No obligations.</h1>
          <h1 style={{ textAlign: 'center' }}>Cancel any time.</h1>
        </div>
        <Button id="bottom-button" 
        // href='http://my.transferta.ee/auth/signup/private' 
        onClick={toggleModal}
        large>Open my account</Button>
      </Container>
      <Footer />
    </div>
  );
}

export default App;
